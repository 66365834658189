import styled from "styled-components";

const NavButton = styled.button`
  background-color: rgba(64, 87, 109, 0.07);
  color: rgba(36, 49, 61, 0.4);
  height: 100%;
  width: 100%;
  padding: 0 14px;
  cursor: pointer;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 100%;
  vertical-align: middle;
  max-width: 100%;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: rgba(64, 87, 109, 0.15);
  }
`;

export default NavButton;
