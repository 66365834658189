import React from "react";
import styled from "styled-components";
import { Colour } from "../models/colour";

const Dot = styled.span<{ $colour: Colour }>`
  ${(props) => props.$colour === Colour.GREY && `color: #8C959F;`}
  ${(props) => props.$colour === Colour.YELLOW && `color: #F29339;`}
  ${(props) => props.$colour === Colour.GREEN && `color: #5FBA7C;`}
  ${(props) => props.$colour === Colour.RED && `color: #FE4657;`}
`;

type Props = {
  colour: Colour;
};

const StatusDot = ({ colour }: Props) => {
  return <Dot $colour={colour}>●</Dot>;
};

export default StatusDot;
