import styled from "styled-components";
import { Website } from "../components/NEWPURPLEFONT/Website";
// import FeaturesSection from "../components/features";
// import Hero from "../components/hero";
import resets from "../components/_resets.module.css";

const Main = styled.div`
  height: 100%;
  font-family: sans-serif;
  background-color: __backgroundColor__;
  flex-basis: 100%;
  overflow-y: auto;
`;

const Default = () => {
  return (
    <Main className={`${resets.storybrainResets}`}>
      <Website />
    </Main>
  );
};

export default Default;
