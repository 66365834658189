import { memo } from "react";
import type { FC } from "react";
import resets from "../_resets.module.css";
import classes from "./Website.module.css";
import Header from "../header";
import Hero from "../hero";
// import TopFeatures from "../top-features";
// import Footer from "../footer";
// import CTA from "../cta";
// import UseCases from "../use-cases";
// import Testimonials from "../testimonials";

interface Props {
  className?: string;
  hide?: {
    label?: boolean;
    iconLeft?: boolean;
    input?: boolean;
    cursor?: boolean;
    rightContent?: boolean;
    helperText?: boolean;
    iconLeft2?: boolean;
    iconRight?: boolean;
    mediaIcon?: boolean;
    initials?: boolean;
    dot?: boolean;
    mediaIcon2?: boolean;
    initials2?: boolean;
    dot2?: boolean;
    mediaIcon3?: boolean;
    initials3?: boolean;
    dot3?: boolean;
    mediaIcon4?: boolean;
    initials4?: boolean;
    dot4?: boolean;
    mediaIcon5?: boolean;
    initials5?: boolean;
    dot5?: boolean;
    avatar?: boolean;
    avatar2?: boolean;
    avatar3?: boolean;
    avatar4?: boolean;
    avatar5?: boolean;
    icon?: boolean;
    dot6?: boolean;
    iconLeft3?: boolean;
    iconRight2?: boolean;
    iconLeft4?: boolean;
    iconRight3?: boolean;
    iconLeft5?: boolean;
    iconRight4?: boolean;
    iconLeft6?: boolean;
    iconRight5?: boolean;
    iconLeft7?: boolean;
    iconRight6?: boolean;
    iconLeft8?: boolean;
    iconRight7?: boolean;
    iconLeft9?: boolean;
    iconRight8?: boolean;
    label2?: boolean;
    iconLeft10?: boolean;
    input2?: boolean;
    cursor2?: boolean;
    rightContent2?: boolean;
    helperText2?: boolean;
    iconLeft11?: boolean;
    iconRight9?: boolean;
  };
}

export const Website: FC<Props> = memo(function Website(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <Hero />
      <Header />
      {/* <div className={classes.content}>
        <div className={classes.line6}></div>
        <TopFeatures />
        <UseCases />
        <div className={classes.line72}></div>
        <Testimonials />
      </div>
      <div className={classes.footer}>
        <CTA />
        <div className={classes.line73}></div>
        <Footer />
      </div> */}
    </div>
  );
});
