import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Default from "./pages/default";
import Controller from "./pages/controller";
import PrivacyPolicy from "./pages/privacy-policy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Default />} />
        <Route path="/presentation/:magicId" element={<Controller />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
