import React from "react";
import logo from "../assets/pretzel-512x512.png";
import classes from "./NEWPURPLEFONT/Website.module.css";
import styled from "styled-components";

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
`;

const CompanyName = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-family: Inter, system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  padding-left: 12px;
`;

const Header = () => (
  <div className={classes.header}>
    <div className={classes.frame27}>
      <LogoWrapper href="https://copresent.online">
        <Logo src={logo} alt="Co-Present logo" />
        <CompanyName>Co-Present</CompanyName>
      </LogoWrapper>
    </div>
    <div className={classes.nav}>
      {/* <div className={classes.templates}>Templates</div>
      <div className={classes.pricing}>Pricing</div>
      <div className={classes.features}>Features</div>
      <div className={classes.blog}>Blog</div> */}
    </div>
    <div className={classes.frame32}>
      {/* <Button_SizeSmallStyleOutlinedI
        className={classes.button}
        classes={{ ButtonMasterSmall: classes.ButtonMasterSmall }}
        text={{
          label: <div className={classes.label2}>Sign in</div>,
        }}
      /> */}
    </div>
  </div>
);

export default Header;
