import React from "react";
import styled from "styled-components";
import StatusDot from "./status-dot";
import { PresentationStatus } from "../models/presentationStatus";
import { Colour } from "../models/colour";

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const Message = styled.span`
  margin-left: 4px;
`;

interface Props {
  status: PresentationStatus;
}

const ConnectionStatus = ({ status }: Props) => {
  return (
    <Wrapper>
      {status === PresentationStatus.ONLINE && (
        <>
          <StatusDot colour={Colour.GREEN} />
          <Message>Connected</Message>
        </>
      )}
      {status === PresentationStatus.CONNECTING && (
        <>
          <StatusDot colour={Colour.YELLOW} />
          <Message>Connecting...</Message>
        </>
      )}
      {status === PresentationStatus.CLOSED && (
        <>
          <StatusDot colour={Colour.RED} />
          <Message>Disconnected</Message>
        </>
      )}
      {status === PresentationStatus.NOT_STARTED && (
        <>
          <StatusDot colour={Colour.GREY} />
          <Message>Not Started</Message>
        </>
      )}
    </Wrapper>
  );
};

export default ConnectionStatus;
