import React from "react";
import webStoreBadge from "../assets/chrome-web-store-badge.png";
import styled from "styled-components";

const StoreImg = styled.img`
  width: 300px;
`;

const ChromeStoreButton = () => (
  <a href="https://chrome.google.com/webstore/detail/co-present/bbfdjjkhcmdpcjodlhnaijoionkjpiae">
    <StoreImg src={webStoreBadge} alt="Get Co-Present from Chrome Web Store" />
  </a>
);

export default ChromeStoreButton;
